<template>
  <ModuleForm
    ref="galleryForm"
    :module-name="$t('moduleForm.gallery')"
    :module="gallery"
    :show-header-info="disabled"
    :tabs="tabs"
    @tab-change="(index) => tabIndex = index"
  >
    <template #header-buttons>
      <ModuleFormButton
        v-if="disabled"
        icon="edit"
        @click="goToEdit"
      />
      <ModuleFormButton
        v-else
        :title="$t('buttons.save')"
        @click="save"
      />
      <ModuleFormDeleteButton
        v-if="gallery.id"
        :disabled="galleryArticles.length > 0"
        :record-id="gallery.id"
        store-name="gallery"
        list-router-path="gallery"
      />
      <ModuleFormButton
        v-if="!isModal"
        icon="close"
        @click="$router.push('/gallery')"
      />
    </template>
    <template #form-content>
      <div
        v-show="tabIndex === 0"
        class="form-content"
      >
        <div class="form-content__left">
          <Input
            v-model="gallery.title"
            @blur="$v.gallery.title.$touch()"
            :error="$v.gallery.title.$error"
            id="gallery_title"
            :label="$t('modal.title')"
            :placeholder="$t('gallery.title_placeholder')"
            required
            :disabled="disabled"
          />
          <Textarea
            v-model="gallery.description"
            disable-form-group
            id="gallery_description"
            :label="$t('modal.description')"
            :rows="5"
            class="form-group"
            :disabled="disabled"
          />
          <div class="two-columns">
            <Select
              v-model="gallery.site"
              @blur="$v.gallery.site.$touch()"
              :error="$v.gallery.site.$error"
              :options="sites"
              id="gallery_site"
              :label="$t('modal.site')"
              required
              :disabled="disabled"
              class="two-columns__column"
            />
            <div class="two-columns__column">
              <div class="add-tag-button">
                <CreateTagButton
                  v-if="searchedTag"
                  :searchedTag="searchedTag"
                  @set-tag="addTag"
                />
              </div>
              <div class="title-text">
                {{ $t('modal.tags') }}
                <span class="required">*</span>
              </div>
              <MultiSelect
                v-model="gallery.expanded.tags"
                :options="tags"
                label="title"
                track-by="id"
                :preselect-first="true"
                :loading="callingAPI"
                :internal-search="false"
                :options-limit="300"
                :limit="10"
                :max-height="600"
                :show-no-results="false"
                open-direction="bottom"
                @search-change="findTag"
                id="gallery_tags"
                class="form-group"
                :disabled="disabled"
              />
            </div>
          </div>
          <OutlinedCheckbox
            v-model="gallery.public"
            :label="$t('gallery.public_gallery')"
            id="gallery_public"
            disableFormGroup
            :tooltip="$t('dam.public_info')"
            :disabled="disabled"
          />
        </div>
        <div class="form-content__separator">
          <div class="form-content__separator__line"/>
        </div>
        <div class="form-content__right">
          <UsedInArticlesTable
            v-if="gallery.id"
            filter="galleryGroup"
            :id="gallery.id"
            @set-data="setGalleryArticles"
          />
        </div>
      </div>
      <div v-show="tabIndex === 1">
        <MediaUploadButton
          v-if="!disabled"
          @set-media="addMediaToGallery"
          multiple
          :button-text="$t('buttons.upload_photo_from_computer')"
          data-test="gallery_btn_select_gallery_from_pc"
        />
        <MediaSelectButton
          v-if="!disabled"
          @set-media="addMediaToGallery"
          multiple
          :button-text="$t('buttons.select_photo_from_bank')"
          data-test="gallery_btn_select_gallery_from_dam"
          :isGallery="true"
        />
        <MediaUrlButton
          v-if="!disabled"
          @set-media="addMediaToGallery"
          type-of-btn="url"
          :btn-text="$t('buttons.upload_photo_from_url')"
          data-test="gallery_btn_select_gallery_from_url"
        />
        <MediaUrlButton
          v-if="!disabled"
          @set-media="addMediaToGallery"
          type-of-btn="embed"
          :btn-text="$t('buttons.upload_photo_from_url_social')"
          data-test="recipe_btn_select_gallery_from_url"
        />
        <MediaEditButton
          v-if="!disabled"
          :selectedMedia="gallery.expanded.medias"
          :isGallery="true"
        />
        <Gallery
          :disabled="disabled"
          class="gallery"
          :gallery="gallery.expanded.medias"
          parent-store-name="gallery"
          @set-hero-image="setImageFromGallery"
          :show-hero-button="false"
          :show-body-button="false"
          :show-info="true"
        />
      </div>
    </template>
  </ModuleForm>
</template>

<script>
import { mapState } from 'vuex'
import NotifyService from '@/services/NotifyService'
import { required } from 'vuelidate/lib/validators'
import MediaService from '../../services/media/MediaService'
import Input from '@/components/form/inputs/Input'
import Textarea from '@/components/form/Textarea'
import Select from '@/components/form/select/Select'
import MultiSelect from '@/components/form/select/MultiSelect'
import Gallery from '@/components/article/ArticleGallery'
import CreateTagButton from '@/components/article/ArticleCreateTagButton'
import MediaSelectButton from '@/components/shared/MediaSelectButton'
import MediaUploadButton from '@/components/shared/MediaUploadButton'
import MediaUrlButton from '@/components/shared/MediaUrlButton'
import MediaEditButton from '@/components/shared/MediaEditButton'
import UsedInArticlesTable from '@/components/article/UsedInArticlesTable'
import ModuleForm from '@/components/shared/ModuleForm'
import ModuleFormButton from '@/components/shared/ModuleFormButton'
import ModuleFormDeleteButton from '@/components/shared/ModuleFormDeleteButton'
import OutlinedCheckbox from '@/components/form/OutlinedCheckbox'
import GalleryModel from '@/model/GalleryModel'

export default {
  name: 'GalleryNewView',
  components: {
    OutlinedCheckbox,
    ModuleFormDeleteButton,
    ModuleFormButton,
    ModuleForm,
    Input,
    Textarea,
    Select,
    MultiSelect,
    Gallery,
    CreateTagButton,
    MediaSelectButton,
    MediaUploadButton,
    MediaUrlButton,
    MediaEditButton,
    UsedInArticlesTable
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    gallery () {
      return this.$store.getters['gallery/detail']
    },
    sites () {
      return this.$store.getters['site/enabledSites']()
    }
  },
  data () {
    return {
      searchedTag: '',
      tags: [],
      galleryArticles: [],
      tabs: [
        { name: this.$t('gallery.main_tab'), error: false },
        { name: this.$t('gallery.gallery_tab'), error: false }
      ],
      tabIndex: 0
    }
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    isModal: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    addTag (tag) {
      this.gallery.expanded.tags.push(tag)
      this.searchedTag = ''
    },
    findTag (query) {
      this.searchedTag = ''
      this.$store.dispatch('tag/fetchByTitle', { query, view: 'gallery' })
        .then(() => {
          this.tags = this.$store.getters['tag/tagsWithGalleryCount']
          this.searchedTag = query
        })
    },
    setImage (medias) {
      medias.forEach(media => {
        this.gallery.expanded.image = media
      })
    },
    setImageFromGallery (medias) {
      medias.forEach(media => {
        MediaService.createMedia(media)
          .then(newMedia => {
            this.setImage([newMedia])
          })
          .catch((error) => {
            console.log(error)
          })
      })
    },
    removeImage () {
      this.gallery.expanded.image = null
    },
    addMediaToGallery (medias) {
      medias.forEach(media => {
        if (this.checkIfAssetExistInGallery(media) === false) {
          MediaService.createMedia(media)
            .then(newMedia => {
              this.gallery.expanded.medias.push(newMedia)
            })
            .catch((error) => {
              console.log(error)
            })
        }
      })
    },
    checkIfAssetExistInGallery (asset) {
      let selected = false
      this.gallery.expanded.medias.forEach(media => {
        if (media.damMediaEmbed.damId === asset.damMediaEmbed.damId) {
          selected = true
        }
      })
      return selected
    },
    async save () {
      this.$v.$touch()
      this.tabs[0].error = this.$v.$invalid
      if (this.$v.$invalid) {
        NotifyService.setErrorMessage(this.$t('notify.please_fill_all_required_fields'))
        return
      }
      try {
        await this.$store.dispatch('gallery/create', this.gallery)
        NotifyService.setSuccessMessage(this.$t('notify.record_was_created'))
        this.goToEdit()
      } catch (err) {
        NotifyService.setErrorMessage(this.$store.getters['gallery/error'])
      }
    },
    async remove () {
      try {
        await this.$store.dispatch('gallery/deleteRecord', this.gallery)
        NotifyService.setSuccessMessage(this.$t('notify.record_was_deleted'))
        this.$router.push('/gallery')
      } catch (err) {
        NotifyService.setErrorMessage(this.$store.getters['gallery/error'])
      }
    },
    setGalleryArticles (articles) {
      this.galleryArticles = articles
    },
    goToEdit () {
      this.$store.commit('gallery/setPreviousTabIndex', this.tabIndex)
      this.$router.push('/gallery/' + this.gallery.id + '/edit')
    }
  },
  validations: {
    gallery: {
      title: {
        required
      },
      site: {
        required
      }
    }
  },
  mounted () {
    this.$store.commit('gallery/storeDetail', this._.cloneDeep(GalleryModel))
  }
}
</script>

<style lang="scss" scoped>
.two-columns {
  display: flex;
  gap: 2%;
  @include bp(0 7) {
    flex-wrap: wrap;
  }
  &__column {
    @include bp(0 7) {
      flex: 0 0 100%;
    }
    @include bp(11) {
      flex: 0 0 49%;
    }
  }
}
.add-tag-button {
  position: absolute;
  z-index: 100;
}
.gallery {
   margin-top: rem(10px);
 }
</style>
